import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import * as React from 'react';
import mediaQueries from '../constants/responsive';
import spacing from '../constants/spacing';

export type PanelTextPros = PanelTextDto;

const useStyles = makeStyles(() =>
    createStyles({
        panelItem: {
            boxSizing: 'border-box',
            [mediaQueries.Mobile]: {
                paddingBottom: spacing.Gutter.Mobile,
                paddingRight: spacing.Gutter.Mobile / 2,
                paddingLeft: spacing.Gutter.Mobile / 2,
            },
            [mediaQueries.Tablet]: {
                paddingBottom: spacing.Gutter.Tablet,
                paddingRight: spacing.Gutter.Tablet / 2,
                paddingLeft: spacing.Gutter.Tablet / 2,
            },
            [mediaQueries.AfterTablet]: {
                paddingBottom: spacing.Gutter.Desktop,
                paddingRight: spacing.Gutter.Desktop / 2,
                paddingLeft: spacing.Gutter.Desktop / 2,
            },
        },
        panelFull: {
            width: '100%'
        },
        panelHalf: {
            width: '50%'
        },
        text: {
            '& a': {
                display: 'inline-block',
                backgroundImage: 'linear-gradient(to bottom,rgba(0,0,0,0) 50%,#000 50%)',
                backgroundRepeat: 'repeat-x',
                backgroundSize: '3px 3px',
                backgroundPosition: '0 18px'
            }
        }
    }));

const PanelText: React.FC<PanelTextPros> = ({ Text, Size }) => {
    const cls = useStyles();

    return (
        <div
            className={
                classNames(
                    cls.panelItem,
                    Size === "Full" ? cls.panelFull : cls.panelHalf)}
        >
            <div
                className={cls.text}
                dangerouslySetInnerHTML={{ __html: Text }}
            />
        </div>
    );
}

export default PanelText;