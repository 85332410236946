import Hammer from 'hammerjs';

import classNames from 'classnames';
import * as React from "react";
import withStyles, { StyledComponentProps } from 'react-jss';
import { ISlide, SliderContext, SliderState } from "./Slider";

export interface IMap extends ISlide {
    index?: number;
    x?: number;
    position?: number;
}

const styles = {
    rail: {
        width: '100%',
        height: '100%',
        zIndex: 0
    },
    '$rail *': {
        webkitUserDrag: 'none'
    },
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        transform: 'translateX(50%)'
    },
    railMoveRight: {},
    railMoveLeft: {},
    dragDist: {},
    railDragging: {},
    containerTranslate: {
        height: '100%',
        // transition: 'transform 0.8s cubic-bezier(0.02, 0, 0.48, 0.99)',
        transition: 'transform 0.8s ease',
        '$railDragging &': {
            transition: 'none'
        },
    },
    railItem: {
        position: 'absolute',
        bottom: 0,
        height: '100%',
        boxSizing: 'border-box',
        zIndex: 0,

        '&$railItemCurrent': {
            zIndex: 1
        }
    },
    railItemCurrent: {},
    railItemBefore: {},
    railItemAfter: {},
    railItemNoGrab: {},
    slideControlNext: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        width: '50%',
        zIndex: 1
    },
    slideControlPrevious: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: '50%',
        zIndex: 1
    },
}

export type RailProps = {
    drag?: boolean;
    loop?: boolean;
    toSlide?: (slide: React.ReactNode) => ISlide;
}
type State = {
    maps: IMap[],
    width: number,
    dragDist: number;
}

type Props = RailProps &
    StyledComponentProps<keyof typeof styles>;

class RailUnWraped extends React.Component<Props & SliderState, State> {
    static ScalarDistance = (ev: HammerInput) => {
        return -ev.deltaX;
    }
    static ApplyMap(maps: IMap[]): IMap[] {
        const result = [];
        for (let i = 0; i < maps.length; i++) {
            const m = maps[i];

            const prev: (IMap | undefined) = i !== 0 && result[i - 1] || undefined;
            const prevX: number = prev && prev.x || 0;
            const prevW: number = prev && prev.width || 0;

            const x = prevX + prevW;
            const w = m.width || 0;
            const p = -x - w / 2;

            result.push({
                ...m,
                index: i,
                width: w,
                x,
                position: p
            });
        }
        return result;
    }

    static getDerivedStateFromProps(nextProps: RailProps & SliderState, prevState: State): State {
        const { width, nodes } = nextProps;
        if (prevState.width !== width || prevState.maps.length !== nodes.length) {
            const newMaps = RailUnWraped.ApplyMap(nextProps.nodes.map(nextProps.toSlide!));
            return { ...prevState, width, maps: newMaps };
        }
        return prevState;
    }
    private hammer: HammerManager | undefined;
    private container: React.RefObject<HTMLDivElement>;
    public constructor(props: Props & SliderState) {
        super(props);
        this._handleClick = this._handleClick.bind(this);
        this.container = React.createRef();
        this.state = {
            maps: RailUnWraped.ApplyMap(this.props.nodes.map(this.props.toSlide!)),
            width: props.width,
            dragDist: 0
        };
    }

    componentDidMount() {

        if (this.props.drag && this.container.current && this.state.maps.length > 1) {
            this.hammer = new Hammer(this.container.current);
            this.hammer.get('pan').set({
                direction: Hammer.DIRECTION_HORIZONTAL
            });
            this.hammer.on("panstart", this._OnDragStart.bind(this));
            this.hammer.on("panmove", this._OnDrag.bind(this));
            this.hammer.on("panend", this._OnDragEnd.bind(this));
        }

    }
    componentWillUnmount() {
        if (this.hammer) {
            this.hammer.destroy();
        }
    }

    public render() {
        if (!this.state.maps.length) {
            return null;
        }
        const { current, previous, units, classes } = this.props;

        const translate = this._getMap(current);
        const translate3d = `translate3d(${translate.position || 0}${units},0,0)`;

        const transform = `${translate3d} translateX(${-this.state.dragDist}px)`;
        const torender = this._toRender();

        return (
            <div
                ref={this.container}
                className={classNames(
                    classes!.rail,
                    current > previous ? classes!.railMoveRight : classes!.railMoveLeft,
                    this.state.dragDist !== 0 ? classes!.dragDist : ''
                )}
            >
                <div
                    className={classes!.container}
                    style={{
                        width: `${this.state.width}${units}`
                    }}
                >
                    <div
                        className={classes!.containerTranslate}
                        style={{
                            transform
                        }}
                    >
                        {torender.map((m) => {
                            const i = m.index || 0;

                            return (
                                <div
                                    key={i}
                                    className={classNames(
                                        classes!.railItem,
                                        current === i ? classes!.railItemCurrent : '',
                                        current > i ? classes!.railItemBefore : '',
                                        current < i ? classes!.railItemAfter : '',
                                        this.state.maps.length <= 1 ? classes!.railItemNoGrab : ''
                                    )}
                                    style={{
                                        transform: `translate3d(${m.x}${units},0,0)`,
                                        width: `${m.width}${units}` || 0
                                    }}
                                >
                                    {m.element}
                                </div>);
                        })}
                    </div>
                </div>
            </div>
        );
    }

    private _OnDragStart(ev: HammerInput) {
        return false;
    }

    private _OnDrag(ev: HammerInput) {

        const distance = RailUnWraped.ScalarDistance(ev);
        if (!distance) { return false; }

        this.setState(() => ({ dragDist: distance }));
        return false;
    }

    private _OnDragEnd(ev: HammerInput) {

        this._OnDrag(ev);
        const distance = RailUnWraped.ScalarDistance(ev);
        const container = this.container.current;
        if (!distance) { return; }
        if (!container) { return; }

        const direction = distance / Math.abs(distance);
        const percent = Math.abs(distance / container.clientWidth);
        const vel = Math.abs(ev.velocityX);
        if (percent < 0.3 && vel < .5) {
            this.setState(() => ({ dragDist: 0 }));
            return false;
        }

        const { go, current } = this.props;
        this.setState(() => ({ dragDist: 0 }));
        go(current + Math.ceil(percent) * direction);

        return false;
    }

    private _handleClick(i: number) {
        this.props.go(i);
    }

    private _getMap(i: number): IMap {
        const maps = this.state.maps;
        const count = maps.length;

        const last = maps[count - 1];
        const W = (last.x || 0) + (last.width || 0);
        const n = Math.floor(i / count);
        const index = (i + Math.abs(n) * count) % count;
        const projected = maps[index];

        return {
            ...projected,
            x: (projected.x || 0) + n * W,
            index: i,
            position: (projected.position || 0) - n * W,
        };
    }

    private _getMinMax(i: number) {

        const current = this._getMap(i);
        let minIndex = i - 1;
        let maxIndex = i + 1;

        const available = this.state.width / 2 - (current.width || 0) / 2;

        let availableRight = available;
        while (availableRight > 0) {

            const next = this._getMap(maxIndex);
            const nextWidth = next.width;

            if (!nextWidth) {
                break;
            }
            availableRight -= nextWidth;
            maxIndex += 1;
        }

        let availableLeft = available;
        while (availableLeft > 0) {

            const prev = this._getMap(minIndex);
            const prevWidth = prev.width;
            if (!prevWidth) {
                break;
            }
            availableLeft -= prevWidth;
            minIndex -= 1;
        }
        return { min: minIndex, max: maxIndex };
    }

    private _toRender(): IMap[] {
        const count = this.state.maps.length;
        if (count <= 1) {
            return [
                this._getMap(this.props.current)
            ];
        }

        const result: IMap[] = [];

        const { min: minPrevious, max: maxPrevious } = this._getMinMax(this.props.previous);
        const { min: minCurrent, max: maxCurrent } = this._getMinMax(this.props.current);

        let min = Math.min(minPrevious, minCurrent) - 1;
        let max = Math.max(maxPrevious, maxCurrent) + 1;

        if (!this.props.loop) {
            min = Math.max(min, 0);
            max = Math.min(max, count - 1);
        }

        for (let i = min; i <= max; i++) {
            const map = this._getMap(i);

            result.push(map);
        }

        return result;
    }
}

const Rail: React.SFC<RailProps> = (props: RailProps) => {
    return (
        <SliderContext.Consumer>
            {(state: SliderState) => {
                const p = { ...state, ...props };
                return <RailUnWraped {...p} />;
            }}
        </SliderContext.Consumer>);
};

export default withStyles(styles)(Rail);