import { createStyles, makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'reset-css';
import Api from './_context/Api';
import Responsive from './_context/Responsive';
import Page from './components/Page';
import fonts from './constants/fonts';
import mediaQueries from './constants/responsive';
import stylesheet from './constants/stylesheet';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      'html, body': {
        ...stylesheet.Body
      },
      'a': {
        textDecoration: 'none',
        color: 'inherit'
      },
      'a:hover': {
        color: 'black'
      },
      'em': {
        ...fonts.floribooita
      },
      '#root': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'white'
      },
      '.custom-style-title-big': {
        ...stylesheet.TextStyleOne
      },
      '.custom-style-title-small': {
        ...stylesheet.CustomSmall
      },
      '.custom-style-legend': {
        ...stylesheet.TextStyleFour,
        display: 'inline-block'
      },
      '.custom-style-underline': {
        backgroundImage: 'linear-gradient(to bottom,rgba(0,0,0,0) 50%,#000 50%)',
        backgroundRepeat: 'repeat-x',
        backgroundSize: '3px 3px',
        backgroundPosition: '0 18px'
      },
      '.custom-style-indent': {
        textIndent: 50
      },
      '.custom-style-indent-small': {
        textIndent: 15
      },
      '.custom-style-indent-years': {
        display: 'flex',
        marginBottom: 10
      },
      '.custom-style-indent-date': {
        flex: '0 0 100px'
      },
      '.custom-style-indent-text': {
        flex: '1 1 auto'
      },
    },
    app: {
      height: '100%',
      width: '100%'
    },
  }));

const App: React.FC = () => {

  const cls = useStyles();

  return (
    <Router>
      <Responsive mediaQueries={mediaQueries}>
        <Api>
          <div className={cls.app}>
            <Switch>
              {
                // tslint:disable-next-line:jsx-no-lambda
                <Route render={() => <Page />} />
              }
            </Switch>
          </div>
        </Api>
      </Responsive>
    </Router>
  );
}

export default App;