import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import * as React from 'react';
import { useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { InjectedApiProps, withApi } from '../_context/Api';
import Responsive from '../_context/Responsive';
import Close from '../assets/icons/FlorianGraf_Close.svg';
import Burger from '../assets/icons/FlorianGraf_Menu.svg';
import mediaQueries from '../constants/responsive';
import spacing from '../constants/spacing';
import stylesheet from '../constants/stylesheet';
import { Language } from '../utils/Languages';

export type MenuProps = InjectedApiProps & {
    isHomePage: boolean;
};

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'sticky',
            top: 0,
            height: spacing.MenuHeight,
            zIndex: 2,
            '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: '#f5f5f5',
                zIndex: 1
            },
            [mediaQueries.Mobile]: {
            },
            [mediaQueries.Tablet]: {
            },
            [mediaQueries.AfterTablet]: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: spacing.Gutter.Desktop / 2,
                paddingRight: spacing.Gutter.Desktop / 2,
            }
        },
        isHomePage: {
            // marginTop: -spacing.MenuHeight,
        },
        menuTitle: {
            position: 'absolute',
            zIndex: 2,
            [mediaQueries.Mobile]: {
            },
            [mediaQueries.Tablet]: {
            },
            [mediaQueries.AfterTablet]: {
                top: '50%',
                transform: 'translateY(-50%)',
                left: spacing.Gutter.Desktop / 2,
            }
        },
        menuLink: {
            position: 'relative',
            margin: '0 10px',
            zIndex: 2,
        },
        activeMenuLink: {
            backgroundImage: 'linear-gradient(to bottom,rgba(0,0,0,0) 50%,#000 50%)',
            backgroundRepeat: 'repeat-x',
            backgroundSize: '3px 3px',
            [mediaQueries.Mobile]: {
                backgroundPosition: '0 37px',
            },
            [mediaQueries.Tablet]: {
                backgroundPosition: '0 37px',
            },
            [mediaQueries.AfterTablet]: {
                backgroundPosition: '0 20px',
            }
        },
        switchLang: {
            position: 'absolute',
            zIndex: 2,
            [mediaQueries.Mobile]: {
            },
            [mediaQueries.Tablet]: {
            },
            [mediaQueries.AfterTablet]: {
                top: '50%',
                transform: 'translateY(-50%)',
                right: spacing.Gutter.Desktop / 2,
            }
        },
        burger: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 2,
            lineHeight: 0
        },
        burgerIcon: {
            width: 40,
            height: 40
        },
        menuMobileOuter: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100vh',
            overflow: 'hidden',
            zIndex: 0,
            pointerEvents: 'none',
            transform: 'translateY(-100%)',
            transition: 'transform .3s ease',
        },
        menuMobile: {
            position: 'absolute',
            top: spacing.MenuHeight,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'white',
            overflow: 'auto',
            '-webkit-overflow-scrolling': 'touch',
            pointerEvents: 'initial',
            [mediaQueries.Mobile]: {
                padding: spacing.Gutter.Mobile
            },
            [mediaQueries.Tablet]: {
                padding: spacing.Gutter.Tablet
            },
        },
        menuMobileShow: {
            transform: 'translateY(0%)',
            ...stylesheet.TextStyleTwo
        },
        menuTitleMobile: {
            display: 'block',
            textAlign: 'center',
            paddingBottom: 20,
            ...stylesheet.TextStyleTwo
        },
        menuLinkMobile: {
            textAlign: 'center',
            paddingBottom: 20,
            ...stylesheet.TextStyleTwo,
            '&:last-child': {
                paddingBottom: 0,
            }
        },
        switchLangMobile: {
            paddingBottom: 20,
            ...stylesheet.TextStyleTwo
        },
        addOverflowHidden: {
            overflow: 'hidden'
        }
    }));

const Menu: React.FC<MenuProps> = ({ apiCtx: { app }, isHomePage }) => {
    const scrollContainer = document.scrollingElement ? document.scrollingElement : document.documentElement;
    const cls = useStyles();
    const lang = Language;
    const nav = useRef<HTMLHeadElement>(null);
    const [showMenu, setShowMenu] = useState(false);

    const handleOpenMenu = () => {
        if (showMenu) {
            scrollContainer.classList.remove(cls.addOverflowHidden);
        }
        if (!showMenu) {
            scrollContainer.classList.add(cls.addOverflowHidden);
        }

        nav.current!.scrollIntoView({ behavior: 'smooth' });
        setShowMenu(!showMenu);
    };

    return (
        <>
            <nav ref={nav} className={classNames(cls.root, isHomePage && cls.isHomePage)}>
                <Responsive.Consumer steps={["AfterTablet"]}>
                    <Link to={`/${Language}`} className={cls.menuTitle}>Florian Graf</Link>
                    {app && app.Menus.map(m =>
                        <NavLink
                            key={m.UID}
                            to={`/${lang}/${m.UID}`}
                            className={cls.menuLink}
                            activeClassName={cls.activeMenuLink}
                        >
                            {m.Title}
                        </NavLink>)
                    }
                    {lang === 'en' && <a href={`?switch-lang=de`} className={cls.switchLang}>DE</a>}
                    {lang === 'de' && <a href={`?switch-lang=en`} className={cls.switchLang}>EN</a>}
                </Responsive.Consumer>

                <Responsive.Consumer steps={["Mobile", "Tablet"]}>
                    <>
                        <a
                            className={cls.burger}
                            // tslint:disable-next-line:jsx-no-lambda
                            onClick={() => handleOpenMenu()}
                        >
                            {showMenu && <img src={Close} className={cls.burgerIcon} />}
                            {!showMenu && <img src={Burger} className={cls.burgerIcon} />}
                        </a>
                        <div className={classNames(cls.menuMobileOuter, showMenu && cls.menuMobileShow)}>
                            <div className={cls.menuMobile}>
                                <Link
                                    to={`/${Language}`}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    onClick={() => handleOpenMenu()}
                                    className={cls.menuTitleMobile}>
                                    Florian Graf
                                </Link>
                                {app && app.Menus.map(m =>
                                    <div
                                        key={m.UID}
                                        className={cls.menuLinkMobile}
                                    >
                                        <NavLink
                                            to={`/${lang}/${m.UID}`}
                                            // tslint:disable-next-line:jsx-no-lambda
                                            onClick={() => handleOpenMenu()}
                                            activeClassName={cls.activeMenuLink}
                                        >
                                            {m.Title}
                                        </NavLink>
                                    </div>)
                                }
                                {lang === 'en' &&
                                    <div className={cls.menuLinkMobile}>
                                        <a href={`?switch-lang=de`} className={cls.switchLangMobile}>DE</a>
                                    </div>
                                }
                                {lang === 'de' &&
                                    <div className={cls.menuLinkMobile}>
                                        <a href={`?switch-lang=en`} className={cls.switchLangMobile}>EN</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                </Responsive.Consumer>
            </nav>
        </>
    );
}

export default withApi(Menu);