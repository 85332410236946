import { createStyles, makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Responsive from '../_context/Responsive';
import mediaQueries from '../constants/responsive';
import spacing from '../constants/spacing';
import stylesheet from '../constants/stylesheet';
import Image from './Image';

export type OverviewItemsPros = {
    items: OverviewItemDto[];
};

const useStyles = makeStyles(() =>
    createStyles({
        item: {
            position: 'relative',
            boxSizing: 'border-box',
            [mediaQueries.Mobile]: {
                flex: '0 0 50%',
                width: '50%',
                paddingLeft: spacing.Gutter.Mobile / 2,
                paddingRight: spacing.Gutter.Mobile / 2
            },
            [mediaQueries.Tablet]: {
                flex: '0 0 50%',
                width: '50%',
                paddingLeft: spacing.Gutter.Tablet / 2,
                paddingRight: spacing.Gutter.Tablet / 2
            },
            [mediaQueries.AfterTablet]: {
                flex: '0 0 25%',
                width: '25%',
                paddingLeft: spacing.Gutter.Desktop / 2,
                paddingRight: spacing.Gutter.Desktop / 2
            }
        },
        itmeImage: {
            position: 'relative',
            height: 0,
            paddingBottom: `${2 / 3 * 100}%`,
            overflow: 'hidden'
        },
        figure: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            objectPosition: 'center',
            OObjectPosition: 'bottom center'
        },
        itemLegend: {
            overflow: 'hidden',
            paddingBottom: 5,
            boxSizing: 'border-box',
            [mediaQueries.Mobile]: {
                height: spacing.OverviewLegendHeight.Mobile,
                textAlign: 'center'
            },
            [mediaQueries.Tablet]: {
                height: spacing.OverviewLegendHeight.Tablet,
                textAlign: 'center'
            },
            [mediaQueries.AfterTablet]: {
                height: spacing.OverviewLegendHeight.Desktop,
            }
        },
        legend: {
            display: 'inline-block',
            ...stylesheet.TextStyleFour,

            '$item:hover &': {
                backgroundImage: 'linear-gradient(to bottom,rgba(0,0,0,0) 50%,#000 50%)',
                backgroundRepeat: 'repeat-x',
                backgroundSize: '2px 2px',
                backgroundPosition: '0 17px'
            }
        },
        itemLink: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: 1,
            [mediaQueries.Mobile]: {
                left: spacing.Gutter.Mobile / 2,
                right: spacing.Gutter.Mobile / 2
            },
            [mediaQueries.Tablet]: {
                left: spacing.Gutter.Tablet / 2,
                right: spacing.Gutter.Tablet / 2
            },
            [mediaQueries.AfterTablet]: {
                left: spacing.Gutter.Desktop / 2,
                right: spacing.Gutter.Desktop / 2
            }
        },
        year: {
            ...stylesheet.TextStyleFour,

            '$item:hover & span': {
                dislplay: 'inline-block',
                backgroundImage: 'linear-gradient(to bottom,rgba(0,0,0,0) 50%,#000 50%)',
                backgroundRepeat: 'repeat-x',
                backgroundSize: '2px 2px',
                backgroundPosition: '0 14px'
            }
        }
    }));

const OverviewItems: React.FC<OverviewItemsPros> = ({ items }) => {
    const cls = useStyles();

    const sizes = "(max-width: 767px) 50vw, (min-width: 768px) 25vw,"

    return (
        <>
            {items.map((item, i) => {
                const mobileText = item.PreviewTextMobile || item.PreviewText || item.Title;
                const desktopText = item.PreviewText || item.Title;
                return (<div key={i} className={cls.item}>
                    <Link to={item.URL} className={cls.itemLink} />
                    <div className={cls.itmeImage}>
                        <figure className={cls.figure}>
                            <Image
                                pictureRef={item.PreviewImage}
                                className={cls.image}
                                sizes={sizes}
                            />
                        </figure>
                    </div>
                    <div className={cls.itemLegend}>
                        <Responsive.Consumer steps={["Mobile", "Tablet"]}>
                            <div
                                className={cls.legend}
                                dangerouslySetInnerHTML={{ __html: mobileText }}
                            />
                        </Responsive.Consumer>
                        <Responsive.Consumer steps={["AfterTablet"]}>
                            <div
                                className={cls.legend}
                                dangerouslySetInnerHTML={{ __html: desktopText }}
                            />
                        </Responsive.Consumer>
                        <div className={cls.year}><span>{item.Year}</span></div>
                    </div>
                </div>);
            })}
        </>
    );
}

export default OverviewItems;