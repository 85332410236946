import { createStyles, makeStyles } from '@material-ui/styles';
import { useState, useContext } from 'react';
import * as React from 'react';
import Hammer from 'react-hammerjs';
import { Link, withRouter } from 'react-router-dom';
import arrowRight from '../assets/icons/FlorianGraf_next.svg';
import arrowLeft from '../assets/icons/FlorianGraf_previous.svg';
import mediaQueries from '../constants/responsive';
import spacing from '../constants/spacing';
import stylesheet from '../constants/stylesheet';
import { Language } from '../utils/Languages';
import Menu from './Menu';
import PanelImage from './PanelImage';
import PanelImages from './PanelImages';
import PanelSlider from './PanelSlider';
import PanelText from './PanelText';
import PanelTitle from './PanelTitle';
import classNames from 'classnames';
import { __RouterContext } from 'react-router';
import Responsive from '../_context/Responsive';
export type ProjectPros = { page: ProjectDto };

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
        titleContainer: {
            position: 'relative',
            maxWidth: 800,
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [mediaQueries.Mobile]: {
                padding: spacing.Gutter.Mobile,
            },
            [mediaQueries.Tablet]: {
                padding: spacing.Gutter.Tablet,
            },
            [mediaQueries.AfterTablet]: {
                padding: spacing.Gutter.Desktop,
            },
        },
        panContainerWrap: {
            overflow: 'hidden'
        },
        panContainer: {
            transition: 'transform linear 0.2s'
        },
        panContainerDragging: {
            transition: 'none'
        },
        prevNextProject: {
            position: 'fixed',
            top: '50vh',
            width: 0,
            '&:after': {
                content: '""',
                position: 'absolute',
                top: '-50vh',
                height: '100vh',
                width: '100vw',
                background: '#efefef',
                zIndex: -1
            },
            marginTop: spacing.MenuHeight / 2,
        },
        prevProject: {
            left: 0,
            '&:after': {
                left: '-100vw',
            }
        },
        nextProject: {
            right: 0,
            '&:after': {
                left: '0',
            }
        },
        prevNextProjectText: {

            position: 'absolute',
            left: 0,
            top: 0,
            padding: 30,
            zIndex: 0,
            whiteSpace: 'nowrap',
            textAlign: 'center',
            '$prevProject &': {
                transform: 'translate(-50%, -100%) rotate(-90deg)',
                transformOrigin: 'bottom center',
            },
            '$nextProject &': {
                transform: 'translate(-50%, 0) rotate(-90deg)',
                transformOrigin: 'top center',
            }
        },
        prevNextProjectTextTitle: {
            fontStyle: 'italic'
        },
        prevProjectLink: {
            lineHeight: 0,
            flex: '0 0 auto'
        },
        title: {
            flex: '1 1 100%',
            textAlign: 'center',
            [mediaQueries.Mobile]: {
                ...stylesheet.TextStyleTwo
            },
            [mediaQueries.Tablet]: {
                ...stylesheet.TextStyleTwo
            },
            [mediaQueries.AfterTablet]: {
                ...stylesheet.TextStyleOne
            },
        },
        nextProjectLink: {
            lineHeight: 0,
            flex: '0 0 auto'
        },
        content: {
            maxWidth: 800,
            margin: '0 auto',
            display: 'flex',
            flexWrap: 'wrap',
            [mediaQueries.Mobile]: {
                paddingRight: spacing.Gutter.Mobile / 2,
                paddingLeft: spacing.Gutter.Mobile / 2,
            },
            [mediaQueries.Tablet]: {
                paddingRight: spacing.Gutter.Tablet / 2,
                paddingLeft: spacing.Gutter.Tablet / 2,
            },
            [mediaQueries.AfterTablet]: {
                paddingRight: spacing.Gutter.Desktop / 2,
                paddingLeft: spacing.Gutter.Desktop / 2,
            },
        },
        contentInner: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
        },
        arrow: {
            width: 35,
            height: 35
        }
    }));

const Project: React.FC<ProjectPros> = (props) => {
    const {
        page: {
            Title,
            previousProject,
            previousProjectTitle,
            nextProject,
            nextProjectTitle,
            Panels,
            SliderImages,
            BackgroundColor
        }
    } = props;
    const cls = useStyles();
    const [tx, setTx] = useState(0);
    const [dragging, setDragging] = useState(false);
    const context = useContext(__RouterContext);

    const DIRECTION_NONE = 1;
    const DIRECTION_LEFT = 2;
    const DIRECTION_RIGHT = 4;
    const DIRECTION_UP = 8;
    const DIRECTION_DOWN = 16;

    const onPan = (e: HammerInput) => {
        if (e.offsetDirection === DIRECTION_LEFT || e.offsetDirection === DIRECTION_RIGHT) { 
            setTx(e.deltaX);
        }
    };

    const onPanStart = (e: HammerInput) => {
        setDragging(true);
        e.preventDefault();
        // console.log(e.deltaX);
    };

    const onPanEnd = (e: HammerInput) => {
        setDragging(false);
        e.preventDefault();

        if (Math.abs(tx) > (document.body.clientWidth / 3)) {
            if (tx < 0) {
                context.history.push(`/${Language}/${nextProject}`);
            } else {
                context.history.push(`/${Language}/${previousProject}`);
            }
        } else {
            setTx(0);
        }

    };
    //     <a className={cls.prevNextProjectText}>
    //     <div className={cls.prevNextProjectTextTitle}>{Language === 'de' ? 'vorheriges Projekt:' : 'Previous project:'}</div>
    //     {previousProjectTitle}
    // </a>

    // <a className={cls.prevNextProjectText}>
    //                     <div className={cls.prevNextProjectTextTitle}>{Language === 'de' ? 'nächstes Projekt:' : 'Next project:'}</div>
    //                         {nextProjectTitle}
    //                     </a>
    return (
        <section className={cls.root} style={{ backgroundColor: BackgroundColor }}>
            <Menu isHomePage={false} />

            <div className={cls.panContainerWrap} >
                <div className={classNames(cls.prevNextProject, cls.prevProject, cls.panContainer, dragging && cls.panContainerDragging)} style={{ transform: `translateX(${tx}px)` }} />
                <div className={classNames(cls.prevNextProject, cls.nextProject, cls.panContainer, dragging && cls.panContainerDragging)} style={{ transform: `translateX(${tx}px)` }} />



                <Responsive.Consumer steps={["Mobile", "Tablet"]}>
                    <Hammer
                        onPan={onPan}
                        onPanStart={onPanStart}
                        onPanEnd={onPanEnd}>

                        <div className={classNames(cls.panContainer, dragging && cls.panContainerDragging)} style={{ transform: `translateX(${tx}px)` }}>

                            <nav className={cls.titleContainer}>
                                <Link to={`/${Language}/${previousProject}`} className={cls.prevProjectLink}>
                                    <img src={arrowLeft} className={cls.arrow} />
                                </Link>
                                <h1 className={cls.title}>{Title}</h1>
                                <Link to={`/${Language}/${nextProject}`} className={cls.nextProjectLink}>
                                    <img src={arrowRight} className={cls.arrow} />
                                </Link>
                            </nav>

                            {
                                // SliderImages.length > 0 && <PanelSlider slides={SliderImages} />
                            }

                            <div className={cls.content}>
                                <div className={cls.contentInner}>
                                    {Panels.map(p => {
                                        switch (p.type) {
                                            case "PanelTitle":
                                                const panelTitle = p as PanelTitleDto;
                                                return (<PanelTitle {...panelTitle} />);

                                            case "PanelText":
                                                const panelText = p as PanelTextDto;
                                                return (<PanelText {...panelText} />);

                                            case "PanelImage":
                                                const panelImage = p as PanelImageDto;
                                                return (<PanelImage {...panelImage} />);

                                            case "PanelImages":
                                                const panelImages = p as PanelImagesDto;
                                                return (<PanelImages {...panelImages} />);

                                            default:
                                                return;
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </Hammer>
                </Responsive.Consumer>

                <Responsive.Consumer steps={["AfterTablet"]}>
                    <div className={classNames(cls.panContainer, dragging && cls.panContainerDragging)} style={{ transform: `translateX(${tx}px)` }}>

                        <nav className={cls.titleContainer}>
                            <Link to={`/${Language}/${previousProject}`} className={cls.prevProjectLink}>
                                <img src={arrowLeft} className={cls.arrow} />
                            </Link>
                            <h1 className={cls.title}>{Title}</h1>
                            <Link to={`/${Language}/${nextProject}`} className={cls.nextProjectLink}>
                                <img src={arrowRight} className={cls.arrow} />
                            </Link>
                        </nav>

                        {
                            // SliderImages.length > 0 && <PanelSlider slides={SliderImages} />
                        }

                        <div className={cls.content}>
                            <div className={cls.contentInner}>
                                {Panels.map(p => {
                                    switch (p.type) {
                                        case "PanelTitle":
                                            const panelTitle = p as PanelTitleDto;
                                            return (<PanelTitle {...panelTitle} />);

                                        case "PanelText":
                                            const panelText = p as PanelTextDto;
                                            return (<PanelText {...panelText} />);

                                        case "PanelImage":
                                            const panelImage = p as PanelImageDto;
                                            return (<PanelImage {...panelImage} />);

                                        case "PanelImages":
                                            const panelImages = p as PanelImagesDto;
                                            return (<PanelImages {...panelImages} />);

                                        default:
                                            return;
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </Responsive.Consumer>
            </div>
        </section >
    );
}

export default Project;