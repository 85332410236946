export const mobileSize = 690;
export const tabletSize = 995;
export const desktopSize = 1440;

const mediaQueries = {
    'Mobile': `@media screen and (max-width: ${mobileSize - 1}px)`,
    'AfterMobile': `@media screen and (min-width: ${mobileSize}px)`,
    'BeforeTablet': `@media screen and (max-width: ${tabletSize - 1}px)`,
    'Tablet': `@media screen and (min-width: ${mobileSize}px) and (max-width: ${tabletSize - 1}px)`,
    'AfterTablet': `@media screen and (min-width: ${tabletSize}px)`,
    'Laptop': `@media screen and (min-width: ${tabletSize}px) and (max-width: ${desktopSize - 1}px)`,
    'Desktop': `@media screen and (min-width: ${desktopSize}px)`
};

export default mediaQueries;