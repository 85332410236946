import { createStyles, makeStyles } from '@material-ui/styles';
import * as React from 'react';

const rotate = {
  from: {
    transformOrigin: 'center',
    transform: `translate3d(-50%,-50%,0) rotate3d(0, 0, 1, 0)`
  },
  to: {
    transformOrigin: 'center',
    transform: `translate3d(-50%,-50%,0) rotate3d(0, 0, 1, 360deg)`,
  }
};

const useStyles = makeStyles(() =>
  createStyles({
    spin: {
      width: 40,
      height: 40,
      position: 'absolute',
      left: '50%',
      top: '50%',
      zIndex: 100,
      transform: `translate(-50%, -50%)`, 
      borderTop: '1px solid rgba(237,237,237,.8)',
      borderRight: '1px solid rgba(237,237,237,.8)',
      borderBottom: '1px solid rgba(237,237,237,.8)',
      borderLeft: '1px solid #000',
      borderRadius: '50%',
      animationName: '$rotate',
      animationIterationCount: 'infinite',
      animationDuration: '1s',
      animationTimingFunction: 'linear',

      // '&:before': {
      //   content: '""',
      //   position: 'absolute',
      //   top: 0,
      //   right: 0,
      //   left: 0,
      //   bottom: 0,
      // }
    },
    '@keyframes rotate': rotate
  }));

export type SpinnerProps = { modifier?: string };
const Spinner: React.SFC<SpinnerProps> = (props) => {
  const cls = useStyles();
  return (
    <div className={cls.spin} />
  );
};

export default Spinner;