import { createStyles, makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import * as React from 'react';
import mediaQueries from '../constants/responsive';
import spacing from '../constants/spacing';
import stylesheet from '../constants/stylesheet';
import Image from './Image';
import Menu from './Menu';
import OverviewItems from './OverviewItems';

export type HomePagePros = { page: HomePageDto };

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'relative',
            width: '100%',
            height: '100%',
            zIndex: 0
        },
        hero: {
            position: 'relative',
            width: '100%',
            height: '100%',
        },
        heroInner: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: spacing.MenuHeight,
        },
        heroImage: {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        content: {
            position: 'relative',
            minHeight: '100vh',
            marginTop: -spacing.MenuHeight,
            backgroundColor: 'white',
            overflow: 'hidden',
            zIndex: 0
        },
        contentInner: {
            maxWidth: spacing.WebSiteMaxWidth,
            margin: '0 auto',
            display: 'flex',
            flexWrap: 'wrap',
            zIndex: 0,
            [mediaQueries.Mobile]: {
                paddingLeft: spacing.Gutter.Mobile / 2,
                paddingRight: spacing.Gutter.Mobile / 2,
                paddingTop: spacing.Gutter.Mobile,
                paddingBottom: spacing.Gutter.Mobile,
            },
            [mediaQueries.Tablet]: {
                paddingLeft: spacing.Gutter.Tablet / 2,
                paddingRight: spacing.Gutter.Tablet / 2,
                paddingTop: spacing.Gutter.Tablet,
                paddingBottom: spacing.Gutter.Tablet,
            },
            [mediaQueries.AfterTablet]: {
                paddingLeft: spacing.Gutter.Desktop / 2,
                paddingRight: spacing.Gutter.Desktop / 2,
                paddingTop: spacing.Gutter.Desktop,
                paddingBottom: spacing.Gutter.Desktop,
            }
        },
        heroText: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 500,
            color: 'white',
            zIndex: 1,
            ...stylesheet.HeroText,
            textAlign: 'center'
        }
    }));

const HomePage: React.FC<HomePagePros> = ({ page: { Images, IntroText, HomePageItems } }) => {
    const cls = useStyles();
    const [heroImage, setHeroImage] = useState<PictureRef>();

    useEffect(
        () => {
            let randomImage = Images[Math.floor(Math.random() * Images.length)];

            // not the best but...
            if (randomImage === heroImage) {
                randomImage = Images[Math.floor(Math.random() * Images.length)];
            };
            setHeroImage(randomImage);
        },
        [heroImage, Images]
    );

    return (
        <div className={cls.root}>
            <div className={cls.hero}>
                <figure className={cls.heroInner}>
                    {IntroText && <div className={cls.heroText} dangerouslySetInnerHTML={{ __html: IntroText }} />}
                    {heroImage &&
                        <Image pictureRef={heroImage} sizes="100vw" className={cls.heroImage} />
                    }
                </figure>
            </div>
            <div className={cls.content}>
                <Menu isHomePage={true} />
                <div className={cls.contentInner}>
                    <OverviewItems items={HomePageItems} />
                </div>
            </div>
        </div>
    );
}

export default HomePage;