import { createStyles, makeStyles } from '@material-ui/styles';
import * as React from 'react';
import mediaQueries from '../constants/responsive';
import spacing from '../constants/spacing';
import stylesheet from '../constants/stylesheet';
import Menu from './Menu';
import PanelImage from './PanelImage';
import PanelImages from './PanelImages';
import PanelSlider from './PanelSlider';
import PanelText from './PanelText';
import PanelTitle from './PanelTitle';

export type PanelPagePros = { page: PanelPageDto };

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
        titleContainer: {
            position: 'relative',
            maxWidth: 800,
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            [mediaQueries.Mobile]: {
                padding: spacing.Gutter.Mobile,
            },
            [mediaQueries.Tablet]: {
                padding: spacing.Gutter.Tablet,
            },
            [mediaQueries.AfterTablet]: {
                padding: spacing.Gutter.Desktop,
            },
        },
        prevProjectLink: {
            flex: '0 0 auto'
        },
        title: {
            flex: '1 1 100%',
            textAlign: 'center',
            [mediaQueries.Mobile]: {
                ...stylesheet.TextStyleTwo
            },
            [mediaQueries.Tablet]: {
                ...stylesheet.TextStyleTwo
            },
            [mediaQueries.AfterTablet]: {
                ...stylesheet.TextStyleOne
            },
        },
        nextProjectLink: {
            flex: '0 0 auto'
        },
        content: {
            maxWidth: 800,
            margin: '0 auto',
            display: 'flex',
            flexWrap: 'wrap',
            [mediaQueries.Mobile]: {
                paddingTop: spacing.Gutter.Mobile,
                paddingRight: spacing.Gutter.Mobile / 2,
                paddingLeft: spacing.Gutter.Mobile / 2,
            },
            [mediaQueries.Tablet]: {
                paddingTop: spacing.Gutter.Tablet,
                paddingRight: spacing.Gutter.Tablet / 2,
                paddingLeft: spacing.Gutter.Tablet / 2,
            },
            [mediaQueries.AfterTablet]: {
                paddingTop: spacing.Gutter.Desktop,
                paddingRight: spacing.Gutter.Desktop / 2,
                paddingLeft: spacing.Gutter.Desktop / 2,
            },
        },
        contentInner: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
        }
    }));

const PanelPage: React.FC<PanelPagePros> = ({ page: { Panels, SliderImages, BackgroundColor } }) => {
    const cls = useStyles();

    return (
        <section className={cls.root} style={{ backgroundColor: BackgroundColor }}>
            <Menu isHomePage={false} />
            {SliderImages.length > 0 && <PanelSlider slides={SliderImages} />}
            <div className={cls.content}>
                <div className={cls.contentInner}>
                    {Panels.map((p, i) => {
                        switch (p.type) {
                            case "PanelTitle":
                                const panelTitle = p as PanelTitleDto;
                                return (<PanelTitle {...panelTitle} key={`${i}`} />);

                            case "PanelText":
                                const panelText = p as PanelTextDto;
                                return (<PanelText {...panelText} key={`${i}`} />);

                            case "PanelImage":
                                const panelImage = p as PanelImageDto;
                                return (<PanelImage {...panelImage} key={`${i}`} />);

                            case "PanelImages":
                                const panelImages = p as PanelImagesDto;
                                return (<PanelImages {...panelImages} />);

                            default:
                                return null;
                        }
                    })}
                </div>
            </div>
        </section>
    );
}

export default PanelPage;