export default {
    'floribooita': {
        fontFamily: 'floribooita'
    },
    'floriboo': {
        fontFamily: 'floriboo'
    },
    'cs': {
        fontFamily: 'cs'
    }
};