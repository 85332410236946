import * as React from 'react';

type ResponsiveCtx = {
    steps: string[];
};

const initState: ResponsiveCtx = {
    steps: []
}
type Props = {
    mediaQueries: { [key: string]: string }
}
const ResponsiveReactContext = React.createContext<ResponsiveCtx>(initState);

class Responsive extends React.Component<Props, ResponsiveCtx> {
    public static Consumer: React.FunctionComponent<{ steps: string[] }> = (props) => {
        return (
            <ResponsiveReactContext.Consumer>
                {(ctx) => props.steps.filter(x => ctx.steps.includes(x)).length ?
                    props.children : null
                }
            </ResponsiveReactContext.Consumer>
        );
    };
    constructor(props: Props) {
        super(props);
        this._resize = this._resize.bind(this);
        this.state = {
            steps: []
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this._resize);
        this._resize();
    }

    public render() {
        return (
            <ResponsiveReactContext.Provider value={this.state}>
                {this.props.children}
            </ResponsiveReactContext.Provider>
        );
    }

    private _resize() {
        const { mediaQueries } = this.props;
        this.setState(() => ({
            steps: Object.keys(mediaQueries)
                .filter(
                    m => window.matchMedia(mediaQueries[m].replace("@media ","")).matches
                )
        }));

    }

}

export default Responsive;