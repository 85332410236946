import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import * as React from 'react';
import mediaQueries from '../constants/responsive';
import spacing from '../constants/spacing';
import Image from './Image';

export type PanelImagePros = PanelImageDto;

const useStyles = makeStyles(() =>
    createStyles({
        panelItem: {
            boxSizing: 'border-box',
            [mediaQueries.Mobile]: {
                paddingBottom: spacing.Gutter.Mobile,
                paddingRight: spacing.Gutter.Mobile / 2,
                paddingLeft: spacing.Gutter.Mobile / 2,
            },
            [mediaQueries.Tablet]: {
                paddingBottom: spacing.Gutter.Tablet,
                paddingRight: spacing.Gutter.Tablet / 2,
                paddingLeft: spacing.Gutter.Tablet / 2,
            },
            [mediaQueries.AfterTablet]: {
                paddingBottom: spacing.Gutter.Desktop,
                paddingRight: spacing.Gutter.Desktop / 2,
                paddingLeft: spacing.Gutter.Desktop / 2,
            },
        },
        panelFull: {
            width: '100%'
        },
        panelHalf: {
            width: '50%'
        }, panelImage: {
            position: 'relative',
            height: 0,
        },
        panelLandscape: {
            paddingBottom: '75%'
        },
        panelPortrait: {
            paddingBottom: '130%'
        },
        figure: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    }));

const PanelImage: React.FC<PanelImagePros> = ({ ImageFormat, Size, Image: image }) => {
    const cls = useStyles();
    const sizes = Size === 'Full' ? "(max-width: 767px) 50vw, (min-width: 768px) 900px," : 
        "(max-width: 767px) 50vw, (min-width: 768px) 450px";

    return (
        <div
            className={
                classNames(
                    cls.panelItem,
                    Size === "Full" ? cls.panelFull : cls.panelHalf)}
        >
            <div className={classNames(
                cls.panelImage,
                ImageFormat === "Landscape" ? cls.panelLandscape : cls.panelPortrait)}
            >
                <figure className={cls.figure}>
                    <Image
                        pictureRef={image}
                        className={cls.image}
                        sizes={sizes}
                    />
                </figure>
            </div>
        </div>
    );
}

export default PanelImage;