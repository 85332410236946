import { createStyles, makeStyles } from '@material-ui/styles';
import * as React from 'react';
import mediaQueries from '../constants/responsive';
import spacing from '../constants/spacing';
import stylesheet from '../constants/stylesheet';
import Menu from './Menu';
import OverviewItems from './OverviewItems';

export type OverviewPros = { page: OverviewDto };

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
        content: {
            maxWidth: spacing.WebSiteMaxWidth,
            margin: '0 auto',
            display: 'flex',
            flexWrap: 'wrap',
            [mediaQueries.Mobile]: {
                paddingLeft: spacing.Gutter.Mobile / 2,
                paddingRight: spacing.Gutter.Mobile / 2,
                paddingTop: spacing.Gutter.Mobile,
                paddingBottom: spacing.Gutter.Mobile,
            },
            [mediaQueries.Tablet]: {
                paddingLeft: spacing.Gutter.Tablet / 2,
                paddingRight: spacing.Gutter.Tablet / 2,
                paddingTop: spacing.Gutter.Tablet,
                paddingBottom: spacing.Gutter.Tablet,
            },
            [mediaQueries.AfterTablet]: {
                paddingLeft: spacing.Gutter.Desktop / 2,
                paddingRight: spacing.Gutter.Desktop / 2,
                paddingTop: spacing.Gutter.Desktop,
                paddingBottom: spacing.Gutter.Desktop,
            }
        },
        heroText: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 500,
            color: 'white',
            zIndex: 1,
            ...stylesheet.HeroText,
            textAlign: 'center'
        }
    }));

const Overview: React.FC<OverviewPros> = ({ page: { OverviewItems: items } }) => {
    const cls = useStyles();

    return (
        <div className={cls.root}>
            <Menu isHomePage={false} />
            <div className={cls.content}>
                <OverviewItems items={items} />
            </div>
        </div>
    );
}

export default Overview;