import * as React from 'react';
import Rail from './Slider/Rail';
export type RailZone = 'previous' | 'next' | 'center';
type Props = {
    // leave: () => void;
    // hasCenter: boolean;
    click: (zone: RailZone) => void;
    // hover: (zone: RailZone) => void;
    // next: () => void;
    // hoverNext: () => void;
    // previous: () => void
    // hoverPrevious: () => void;
    paths?: string[];
};

class SliderRail extends React.Component<Props> {
    private container = React.createRef<HTMLDivElement>();
    constructor(props: Props) {
        super(props);
        // this._containerMove = this._containerMove.bind(this);
        // this._debounceMove = debounce(this._debounceMove.bind(this))
        this._containerClick = this._containerClick.bind(this);
    }
    render() {
        return (
            <div
                style={{ width: '100%', height: '100%' }}
                // onMouseMove={this._containerMove}
                // tslint:disable-next-line:jsx-no-lambda
                onMouseDown={this._containerClick}
                // onMouseLeave={this.props.leave}
                ref={this.container}
            >
                <Rail />
            </div>
        );
    }
    // private _containerMove(e: React.MouseEvent) {
    //     e.persist();
    //     this._debounceMove(e);
    // }

    // private _debounceMove(e: React.MouseEvent) {
    //     if (!this.container.current) {
    //         return;
    //     }
    //     const w = this.container.current.clientWidth;
    //     const { hasCenter, hover } = this.props;
    //     if (!hasCenter) {
    //         if (e.clientX > w / 2) {
    //             hover('next');
    //         }
    //         else {
    //             hover('previous');
    //         }
    //     } else {
    //         if (e.clientX < w / 4) {
    //             hover('previous');
    //         }
    //         else if (e.clientX < 3 * w / 4) {
    //             hover('center');
    //         }
    //         else {
    //             hover('next');
    //         }
    //     }

    // }
    private _containerClick(e: React.MouseEvent) {
        if (!this.container.current) {
            return;
        }
        const w = this.container.current.clientWidth;
        const { click } = this.props;

        if (e.clientX < w / 4) {
            click('previous');
        }
        else if (e.clientX < 3 * w / 4) {
            click('center');
        }
        else {
            click('next');
        }

    }
}

export default SliderRail;