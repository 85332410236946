
export default {
    'Gutter': {
        Mobile: 22,
        Tablet: 56,
        Desktop: 64
    },
    'WebSiteMaxWidth': 1200,
    'MenuHeight': 50,
    'OverviewLegendHeight': {
        Mobile: 50,
        Tablet: 70,
        Desktop: 64
    },
};