import * as React from 'react';
import DocumentMeta from 'react-document-meta';
import { RouteComponentProps, withRouter } from 'react-router';
import { InjectedApiProps, withApi } from '../_context/Api';
import HomePage from './HomePage';
import Overview from './Overview';
import PanelPage from './PanelPage';
import Project from './Project';
import Spinner from './Spinner';
import ReactGA from 'react-ga';

export type PageProps = {};

type Props = PageProps &
    RouteComponentProps<any> &
    InjectedApiProps;
type State = {
    page?: IPageDto;
}

class Page extends React.Component<Props, State> {
    private scrollContainer = document.scrollingElement ? document.scrollingElement : document.documentElement;
    constructor(props: Props) {
        super(props);

        this.state = {
            page: props.apiCtx.app && props.apiCtx.app.Page
        }
    }

    componentDidMount() {
        if (!this.state.page) {
            this._fetch();
        }
        if (this.scrollContainer && this.scrollContainer.scrollTop > 0) {
            this.scrollContainer.scrollTop = 0;
        }

        ReactGA.initialize('UA-21115802-8');
        ReactGA.pageview(window.location.pathname);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {

        if (prevProps.location.pathname !== this.props.location.pathname) {
            this._fetch();
        }

        if (this.scrollContainer && this.scrollContainer.scrollTop > 0) {
            this.scrollContainer.scrollTop = 0;
        }
    }

    public render() {
        const { page } = this.state;

        return (
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                {page ? <React.Fragment>
                    <DocumentMeta
                        title={page.PageTitle || ''}
                        description={page.Description || ''}
                    />
                    {page.type === "HomePage" &&
                        <HomePage page={page as HomePageDto} />
                    }
                    {page.type === "Overview" &&
                        <Overview page={page as OverviewDto} />
                    }
                    {page.type === "PanelPage" &&
                        <PanelPage page={page as PanelPageDto} />
                    }
                    {page.type === "Project" &&
                        <Project page={page as ProjectDto} />
                    }
                </React.Fragment> : <Spinner />}
            </div>
        );
    }

    private _fetch() {
        this.setState(() => {
            this.props.apiCtx.get(this.props.location.pathname).then(result => {
                this.setState(() => ({ page: result }));
            });
            return { page: undefined };
        });

    }
}

export default withRouter(withApi(Page));