import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import objectFitImages from 'object-fit-images';
import * as React from 'react';
import { useState } from 'react';
import Spinner from './Spinner';

export type ImageProps =
    React.HTMLAttributes<HTMLElement> & {
        pictureRef?: PictureRef;
        imageSrc?: string;
        sizes?: string;
        srcSet?: string;
        minWidth?: number;
        maxWidth?: number;
        steps?: number;
        alt?: string;
        // includeRatio?: boolean;
        onLoad?: () => void;
        onError?: () => void;
    };

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            position: 'relative',
            width: '100%',
            height: '100%',
            zIndex: 0
        },
        loading: {
            position: 'relative',
            width: '100%',
            height: '100%',
            zIndex: 0
        },
        loaded: {
            opacity: 0
        }
    }));

export const Image: React.FC<ImageProps> = (props) => {

    const classes = useStyles();

    React.useEffect(
        () => {
            objectFitImages();
        },
        []
    );

    const { alt, className } = props;
    const [loading, setLoading] = useState(true);

    const maxWidth = props.maxWidth || 4000;
    const minWidth = props.minWidth || 200;
    let steps = (props.steps || 8);
    const stepSize = (maxWidth - minWidth) / steps;
    const sizes = props.sizes || '100vw';
    const width = props.pictureRef && props.pictureRef.Width || 0;
    const imageSrc = props.pictureRef && props.pictureRef.Src || props.imageSrc || '';

    const srcSet: string[] = [];
    steps++;

    while (steps--) {
        const w = stepSize * steps + minWidth;
        if (width && w > width) { continue; }
        const src = `${imageSrc}?width=${w} ${w}w`;
        srcSet.push(src);
    }

    const atts: React.ImgHTMLAttributes<HTMLImageElement> = {
        alt,
        className,
        srcSet: srcSet.join(', ') || '',
        sizes,
        src: `${imageSrc}`
    };

    atts.onLoad = () => setLoading(false);

    if (props.onError) {
        atts.onError = props.onError;
    }

    return (
        <div className={classes.container}>
            {loading &&
                <div className={classNames(classes.loading, !loading && classes.loaded)} >
                    <Spinner />
                </div>
            }
            <img
                {...atts}
            />
        </div>
    );
}

export default Image;