import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import * as React from 'react';
import arrowLeft from '../assets/icons/FlorianGraf_sliderLeft.svg';
import arrowRight from '../assets/icons/FlorianGraf_sliderRight.svg';
import mediaQueries from '../constants/responsive';
import spacing from '../constants/spacing';
import stylesheet from '../constants/stylesheet';
import Image from './Image';
import { Slider, SliderState } from './Slider';
import CurrentLegend from './Slider/CurrentLegend';
import SliderRail, { RailZone } from './SliderRail';

export type PanelSliderPros = { slides: PictureRef[] };

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'relative',
            width: '100%',
            // [mediaQueries.Mobile]: {
            //     paddingBottom: spacing.Gutter.Mobile,
            // },
            // [mediaQueries.Tablet]: {
            //     paddingBottom: spacing.Gutter.Tablet,
            // },
            // [mediaQueries.AfterTablet]: {
            //     paddingBottom: spacing.Gutter.Desktop,
            // }
        },
        sliderContainer: {
            position: 'relative',
            width: '100%',
            height: 0,
            paddingBottom: '50%',
            overflow: 'hidden'
        },
        sliderContainerInner: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        imageContainer: {
            height: '100%',
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        legend: {
            ...stylesheet.TextStyleFour,
            paddingTop: 10
        },
        legendPadding: {
            [mediaQueries.Mobile]: {
                paddingLeft: spacing.Gutter.Mobile,
                PaddingRight: spacing.Gutter.Mobile,
            },
            [mediaQueries.Tablet]: {
                paddingLeft: spacing.Gutter.Tablet,
                PaddingRight: spacing.Gutter.Tablet,
            },
            [mediaQueries.AfterTablet]: {
                paddingLeft: spacing.Gutter.Desktop,
                PaddingRight: spacing.Gutter.Desktop,
            }
        },
        sliderPrevious: {
            position: 'absolute',
            top: '50%',
            left: 30,
            transform: 'translateY(-50%)',
            zIndex: 1,
            lineHeight: 0,
            cursor: 'pointer',
            [mediaQueries.Mobile]: {
                left: 20,
            }
        },
        sliderNext: {
            position: 'absolute',
            top: '50%',
            right: 30,
            transform: 'translateY(-50%)',
            zIndex: 1,
            lineHeight: 0,
            cursor: 'pointer',
            [mediaQueries.Mobile]: {
                right: 20,
            }
        },
        arrow: {
            width: 60,
            height: 60,
            [mediaQueries.Mobile]: {
                width: 40,
                height: 40,
            },
        }
    }));

const PanelSlider: React.FC<PanelSliderPros> = ({ slides }) => {
    const cls = useStyles();

    const slidesNodes = slides.map((s, i) =>
        <div key={i} className={cls.imageContainer}>
            <Image
                className={cls.image}
                pictureRef={s} sizes="100vw"
            />
        </div>);

    const click = (zone: RailZone, state: SliderState) => {
        const { goNext, goPrevious } = state;

        switch (zone) {
            case 'previous':
                goPrevious();
                break;
            case 'next':
                goNext();
                break;
        }
    };

    const legends = slides.map((s, i) =>
        <div key={i} className={classNames(cls.legend, cls.legendPadding)}>{s.Legend}</div>);

    return (
        <div className={cls.root}>
            <Slider width={100} nodes={slidesNodes} delay={6000} loop={true}>
                <div className={cls.sliderContainer}>
                    <div className={cls.sliderContainerInner}>
                        <Slider.Consumer>
                            {(state: SliderState) => {
                                const c = (zone: RailZone) => click(zone, state);
                                return (
                                    <>
                                        {slides.length > 1 && <>
                                            <a
                                                className={cls.sliderPrevious}
                                                onClick={state.goPrevious}
                                            >
                                                <img src={arrowLeft} className={cls.arrow} />
                                            </a>
                                            <a
                                                className={cls.sliderNext}
                                                onClick={state.goNext}
                                            >
                                                <img src={arrowRight} className={cls.arrow} />
                                            </a>
                                        </>}
                                        < SliderRail
                                            click={c}
                                        />
                                    </>);
                            }}
                        </Slider.Consumer>
                    </div>
                </div>
                <CurrentLegend legends={legends} />
            </Slider>
        </div>
    );
}

export default PanelSlider;