import * as React from "react";

import { SliderState } from ".";
import { SliderContext } from "./Slider";

type CurrentLegendProps = {
    legends: React.ReactNode[]
};
type Props = CurrentLegendProps;

const CurrentLegend: React.SFC<Props> = (props) => {
    return (
        <SliderContext.Consumer>
            {(context: SliderState) => (
                <React.Fragment>
                    {props.legends[context.index]}
                </React.Fragment>)}
        </SliderContext.Consumer>
    );
};

export default CurrentLegend;