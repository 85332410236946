import * as React from 'react';

import { RailProps } from './Rail';

export type SliderProps = RailProps & {
    // images: IPictureRef[];
    nodes: React.ReactNode[];
    width: number;
    units?: 'px' | '%';
    toSlide?: (slide: React.ReactNode) => ISlide;
    delay?: number;
    current?: number;
    onChange?: (i: number) => void;

    // loop?: boolean;// = true
    // shuffle?: boolean;// = true
    // autoplay?: boolean;// = false
    // stopOnInteract?: boolean;// = false
    // opacity?: number;// = false
    // keyboardEvents?: boolean;// = false
    // slidesPerPage: (slider: Slider) => number;

};

export type SliderState = SliderProps & {
    current: number;
    index: number;

    first: boolean;
    last: boolean;
    previous: number;
    width: number;
    playing: boolean;

    go: (i: number) => void;
    goNext: () => void;
    goPrevious: () => void;
};

const initSliderState: SliderState = {
    nodes: [],
    current: 0,
    index: 0,
    previous: 0,
    width: 0,
    first: true,
    last: true,
    playing: false,

    go: (i: number) => (void 0) as never,
    goNext: () => (void 0) as never,
    goPrevious: () => (void 0) as never,

};
export const SliderContext = React.createContext<SliderState>(initSliderState);

export interface ISlide {
    element: React.ReactNode;
    width: number;
}

export class Slider extends React.Component<SliderProps, SliderState> {

    static Rail: any;
    static Control: any;
    static Consumer = SliderContext.Consumer;
    static defaultProps: Partial<SliderProps> = {
        delay: 0,
        current: 0,
        units: '%',
        toSlide: (node: React.ReactNode) => ({ element: node, width: 100 }),
        onChange: () => null as never,
        drag: true,
    };

    private interval: number = 0;

    constructor(props: SliderProps) {
        super(props);
        this._handleKeydown = this._handleKeydown.bind(this);
        this.state = {
            ...initSliderState,
            ...props,
            go: this._Go.bind(this),
            goNext: this._Next.bind(this),
            goPrevious: this._Previous.bind(this),
            playing: !!this.props.delay && this.props.delay > 0 && this.props.nodes.length > 1,
            index: this._getIndex(props.current || 0),
            last: props.current === (this.props.nodes.length - 1),
            first: props.current === 0 || props.current === undefined,
        };
    }

    componentDidMount() {
        window.addEventListener('keydown', this._handleKeydown);
        // create timeout 
        if (this.state.playing) {
            this.interval = setInterval(this._OnInterval.bind(this), this.props.delay)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this._handleKeydown);
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    componentDidUpdate(prevProps: SliderProps, prevState: SliderState) {
        if (this.state.current !== prevState.current && this.props.onChange) {
            this.props.onChange(this.state.current);
        }
    }

    public render() {
        return (
            <SliderContext.Provider value={this.state}>
                {this.props.children}
            </SliderContext.Provider>
        );
    }

    private _getIndex(current: number) {
        const count = this.props.nodes.length;
        const n = Math.floor(current / count);
        return (current + Math.abs(n) * count) % count;
    }

    private _OnInterval() {
        if (!this.state.playing) {
            clearInterval(this.interval);
            return;
        }

        this.setState(prev => {
            const n = this._normalizeIndex(prev.current + 1);
            return {
                current: n,
                previous: prev.current,
                index: this._getIndex(n),
                first: n === 0,
                last: n === (this.props.nodes.length - 1)
            };
        });
    }
    private _handleKeydown(e: KeyboardEvent) {
        switch (e.keyCode) {
            case 37: // left
                this._Previous();
                break;
            case 38: // up
                break;
            case 39: // right
                this._Next();
                break;
            case 40: // down
                break;
            default:
                break;
        }
    }
    private _normalizeIndex(i: number) {
        if (!this.props.loop) {
            const count = this.props.nodes.length;
            return Math.max(Math.min(i, count - 1), 0);
        }
        return i;
    }
    private _Go(i: number) {
        i = this._normalizeIndex(i);
        this.setState(prev => ({
            playing: false,
            current: i,
            previous: prev.current,
            index: this._getIndex(i),
            first: i === 0,
            last: i === (this.props.nodes.length - 1),
        }));
    }

    private _Next() {
        this._Go(this.state.current + 1);
    }

    private _Previous() {
        this._Go(this.state.current - 1);
    }
}

export default Slider;