import fonts from './fonts';
import responsive from './responsive';
import spacing from './spacing';

export const secondaryColor = '#8b8b8b';
type directions = 'top' | 'bottom' | 'left' | 'right' | 'all' | 'sides';

const prefixed = (prefix: string, value: number, multi: number = 1, dir: directions = 'sides') => ({
    [prefix ? `${prefix}Left` : 'left']:
        (dir === 'left' || dir === 'sides' || dir === 'all') && value * multi || undefined,
    [prefix ? `${prefix}Right` : 'right']:
        (dir === 'right' || dir === 'sides' || dir === 'all') && value * multi || undefined,
    [prefix ? `${prefix}Bottom` : 'bottom']:
        (dir === 'bottom' || dir === 'all') && value * multi || undefined,
    [prefix ? `${prefix}Top` : 'top']:
        (dir === 'top' || dir === 'all') && value * multi || undefined,
});

export const bodyFontSize = {
    'Mobile': 18,
    'Tablet': 18,
    'Laptop': 18,
    'Desktop': 18
}

export default {
    'Body': {
        ...fonts.floriboo,
        [responsive.Mobile]: {
            fontSize: bodyFontSize.Mobile,
            lineHeight: '1.28'
        },
        [responsive.Tablet]: {
            fontSize: bodyFontSize.Tablet,
            lineHeight: '1.28'
        },
        [responsive.Laptop]: {
            fontSize: bodyFontSize.Laptop,
            lineHeight: '1.28'
        },
        [responsive.Desktop]: {
            fontSize: bodyFontSize.Desktop,
            lineHeight: '1.28'
        }
    },
    'TextStyleOne': {
        lineHeight: '1.06',
        [responsive.Mobile]: {
        },
        [responsive.Tablet]: {
            fontSize: '3.05rem',
        },
        [responsive.Laptop]: {
            fontSize: '3.05rem',
        },
        [responsive.Desktop]: {
            fontSize: '3.05rem',
        }
    },
    'TextStyleTwo': {
        lineHeight: '1.125',
        [responsive.Mobile]: {
            fontSize: '1.88rem',
        },
        [responsive.Tablet]: {
            fontSize: '1.88rem',
        },
        [responsive.Laptop]: {
            fontSize: '1.88rem',
        },
        [responsive.Desktop]: {
            fontSize: '1.88rem',
        }
    },
    'CustomSmall': {
        lineHeight: '1.125',
        [responsive.Mobile]: {
            fontSize: '1.5rem',
        },
        [responsive.Tablet]: {
            fontSize: '1.88rem',
        },
        [responsive.Laptop]: {
            fontSize: '1.88rem',
        },
        [responsive.Desktop]: {
            fontSize: '1.88rem',
        }
    },
    'TextStyleThree': {
        [responsive.Mobile]: {
            fontSize: '1rem',
        },
        [responsive.Tablet]: {
            fontSize: '1rem',
        },
        [responsive.Laptop]: {
            fontSize: '1rem',
        },
        [responsive.Desktop]: {
            fontSize: '1rem',
        }
    },
    'TextStyleFour': {
        lineHeight: '1',
        [responsive.Mobile]: {
            fontSize: '.78rem',
        },
        [responsive.Tablet]: {
            fontSize: '.78rem',
        },
        [responsive.Laptop]: {
            fontSize: '.78rem',
        },
        [responsive.Desktop]: {
            fontSize: '.78rem',
        }
    },
    'HeroText': {
        ...fonts.cs,
        [responsive.Mobile]: {
            fontSize: '1.88rem',
        },
        [responsive.Tablet]: {
            fontSize: '1.88rem',
        },
        [responsive.Laptop]: {
            fontSize: '1.88rem',
        },
        [responsive.Desktop]: {
            fontSize: '1.88rem',
        }
    },
    'PaddingGutter': (multi: number = 1, dir: directions = 'sides') => ({
        [responsive.Mobile]: prefixed('padding', spacing.Gutter.Mobile, multi, dir),
        [responsive.Tablet]: prefixed('padding', spacing.Gutter.Tablet, multi, dir),
        [responsive.Desktop]: prefixed('padding', spacing.Gutter.Desktop, multi, dir),

    }),
    'MarginGutter': (multi: number = 1, dir: directions = 'sides') => ({
        [responsive.Mobile]: prefixed('margin', spacing.Gutter.Mobile, multi, dir),
        [responsive.Tablet]: prefixed('margin', spacing.Gutter.Tablet, multi, dir),
        [responsive.Desktop]: prefixed('margin', spacing.Gutter.Desktop, multi, dir)
    }),
    'PostionGutter': (multi: number = 1, dir: directions = 'sides') => ({
        [responsive.Mobile]: prefixed('', spacing.Gutter.Mobile, multi, dir),
        [responsive.Tablet]: prefixed('', spacing.Gutter.Tablet, multi, dir),
        [responsive.Desktop]: prefixed('', spacing.Gutter.Desktop, multi, dir)
    })
};