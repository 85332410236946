import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Language } from '../utils/Languages';
import { Subtract } from 'utility-types';

export type Api = {
    app?: AppDto;
    get: (path: string) => Promise<IPageDto>;
    loading: boolean;
};

const initState: Api = {
    get: 0 as never,
    loading: 0 as never
}
const ApiReactContext = React.createContext<Api>(initState);

export type ApiProdiverProps = {};
type Props = RouteComponentProps<any> & ApiProdiverProps;

type State = Api & {
    pageLoading: boolean;
    appLoading: boolean;
}
class ApiProvider extends React.Component<Props, State> {
    public static cache: { [path: string]: IPageDto } = {}
    public static Sections: HTMLElement[] = [];
    constructor(props: Props) {
        super(props);

        const appElt = document.getElementById("app") || undefined;
        const env = (process.env.NODE_ENV === 'production') || undefined;
        const json = env && appElt && appElt.innerHTML || undefined;
        const app = json && JSON.parse(json) as AppDto;

        if (app && app.Page) {
            // with router to find the path:
            // AppProvider.cache[] = app.Page;
        }

        this.state = {
            app: app ? app : undefined,
            get: this._get.bind(this),
            loading: false,
            pageLoading: false,
            appLoading: false
        };
    }

    componentDidMount() {
        if (!this.state.app) {
            this._fetchApp()
                .then(app => this.setState(() => ({ app })))
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if(prevState.loading !== this.state.loading) {
            document.body.dataset.loading = this.state.loading ? "loading" : undefined;
        }
    }

    public render() {
        return (
            <ApiReactContext.Provider value={this.state}>
                {this.props.children}
            </ApiReactContext.Provider>
        );
    }

    private _get(path: string): Promise<IPageDto> {
        const key = `${path || ''}`;
        const cached = ApiProvider.cache[key];
        if (cached) {
            return Promise.resolve(cached);
        }
        return this._fetchPage(path);
    }

    private _fetchPage(path: string): Promise<IPageDto> {
        const requestOptions = {
            headers: {
                'Accept-Language': Language,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        };
        this.setState(({ pageLoading: true, loading: true }));
        return fetch(`${path}?json`, requestOptions)
            .then(response => {
                return response
                    .json();
            })
            .then(pageDto => {
                ApiProvider.cache[path] = pageDto;
                this.setState(({ appLoading }) => ({ pageLoading: false, loading: appLoading }));
                return pageDto;
            })
            .catch(() => {
                this.setState(({ appLoading }) => ({ pageLoading: false, loading: appLoading }));
            });

    }

    private _fetchApp(): Promise<AppDto> {
        const requestOptions = {
            headers: {
                'Accept-Language': Language,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        };
        this.setState(({ appLoading: true, loading: true }));
        return fetch(`/${Language}/api/app`, requestOptions)
            .then(response => {
                return response
                    .json();
            })
            .then(appDto => {
                this.setState(({ pageLoading }) => ({ appLoading: false, loading: pageLoading }));
                return appDto;
            })
            .catch(() => {
                this.setState(({ pageLoading }) => ({ appLoading: false, loading: pageLoading }));
            });
    }

}

export type InjectedApiProps = { apiCtx: Api };
export const withApi =
    <OriginalProps extends InjectedApiProps>(
        Component: React.ComponentType<OriginalProps>
    ): React.FunctionComponent<Subtract<OriginalProps, InjectedApiProps>> => {

        return (props: Subtract<OriginalProps, InjectedApiProps>) => {
            return (
                <ApiReactContext.Consumer>
                    {(apiCtx) => <Component {...{ ...(props as object), apiCtx } as OriginalProps} />}
                </ApiReactContext.Consumer>
            );
        };

    };

export default withRouter(ApiProvider);